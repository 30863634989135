import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["alert", "container", "content"];

    connect() {
        document.addEventListener('calendarChange', this.checkOverflow.bind(this));
    }

    checkOverflow() {
        setTimeout(() => {
            const container = this.containerTarget;
            const content = this.contentTarget;
            const alert = this.alertTarget;

            // console.log(content.scrollHeight)
            // console.log(container.offsetHeight)
            if (content.scrollHeight > container.offsetHeight) {
                alert.style.top = "0";
                alert.style.display = "block";
            } else {
                alert.style.top = "-50px";
                alert.style.display = "none";
            }
        }, 0);
    }

}
