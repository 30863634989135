import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["list", "template"];

    static placeholders = [
        "Run 1 mile",
        "Read 20 pages",
        "Write 500 words",
        "Practice guitar",        
        "Drink water",
        "Meditate",
        "Take vitamins",
        "No alcohol",
        "No coffee",
        "No sugar",
        "No social media",
        "Stretch",
        "Inbox zero",
        "Pet a dog",
        "Walk outside",
    ];

    randomPlaceholder() {
        const randomIndex = Math.floor(Math.random() * this.constructor.placeholders.length);
        return this.constructor.placeholders[randomIndex];
    }

    addItem(event) {
        event.preventDefault();
        const templateDiv = this.templateTarget;
        const clonedDiv = templateDiv.cloneNode(true);
        clonedDiv.querySelector("input[type='text']").placeholder = this.randomPlaceholder();
        clonedDiv.style.display = "";
        
        this.listTarget.appendChild(clonedDiv);
        
        clonedDiv.querySelector("input[type='text']").focus();
    
        document.dispatchEvent(new CustomEvent("calendarChange"));
    }

    removeItem(event) {
        event.preventDefault();
        const div = event.target.closest(".habit");
        div.remove();
        document.dispatchEvent(new CustomEvent("calendarChange"));
    }

    moveUp(event) {
        event.preventDefault();
        const div = event.target.closest(".habit");
        const prevDiv = div.previousElementSibling;
        if (prevDiv) {
            div.parentNode.insertBefore(div, prevDiv);
            document.dispatchEvent(new CustomEvent("calendarChange"));
        }
    }

    moveDown(event) {
        event.preventDefault();
        const div = event.target.closest(".habit");
        const nextDiv = div.nextElementSibling;
        if (nextDiv) {
            div.parentNode.insertBefore(nextDiv, div);
            document.dispatchEvent(new CustomEvent("calendarChange"));
        }
    }
}
