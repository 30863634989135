import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["row", "option"]

    toggle(event) {
        console.log("called")
        
        const option = event.currentTarget
        const rowName = option.dataset.row

        this.rowTargets.forEach(row => {
            row.classList.remove("active");
            row.style.display = "none";
        })
        console.log(this.rowTargets)
        const row = this.rowTargets.find(row => row.dataset.type === rowName)
        row.classList.add("active");
        row.style.display = "flex";

        this.optionTargets.forEach(row => {
            row.classList.add("active");
            row.style.display = "block";
        })
        option.style.display = "none";
    }
}
