import { Controller } from "@hotwired/stimulus"
import Calendar from "../calendar"

export default class extends Controller {
  connect() {
    this.updateCalendar();
    document.addEventListener('calendarChange', this.updateCalendar.bind(this));
  }

  updateCalendar() {
    setTimeout(() => {
      const calendar = new Calendar('.sidebar', '.calendar'); // TODO accept these as params
      calendar.draw();
    }, 0);
  }
}
