import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["element"]

    select(event) {
        this.elementTargets.forEach((element) => {
            element.classList.remove('active');
        });
        event.target.classList.add('active');
        document.dispatchEvent(new CustomEvent("calendarChange"));
    }
    
}
